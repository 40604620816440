<!-- <mat-card>
  <mat-card-header>
    <mat-card-title>Title</mat-card-title>
    <mat-card-subtitle>Subtitle</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    Text content
  </mat-card-content>
  <mat-card-actions align="start">
    <button mat-button (click)="closeDialog(0)">Action1</button>
    <button mat-button (click)="closeDialog(1)">Action1</button>
  </mat-card-actions>
  <mat-card-footer>
    Footer
  </mat-card-footer>
</mat-card> -->


<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-body mt-2 mb-2 fs-6">
      <p>{{displayData?.content}}</p>
    </div>
    <div class="d-flex justify-content-center gap-2">
      <button type="button" class="btn bg-white shadow" *ngIf="showCancel" style="width: 76px;"
        (click)="closeDialog(0)">Cancel</button>
      <button type="button" class="btn bg-white shadow" *ngIf="showOk" style="width: 76px;"
        (click)="closeDialog(1)">Ok</button>
    </div>
  </div>
</div>
