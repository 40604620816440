import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { count, from, map, Observable } from 'rxjs';
import axios, { AxiosInstance } from 'axios'
import { ActivatedRoute } from '@angular/router';

export interface verifyOtpInput {
  mobile_number: string
  otp: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  axiosInstance: AxiosInstance;
  loginData!: any;

  constructor(private route: ActivatedRoute) {
    this.axiosInstance = axios.create({
      baseURL: environment.BaseUrl,
      headers: {
        'Content-Type': 'application/json',
        'app_platform': 'mvaahna'
      }
    });
  }

  isLoggedIn() {
    return !!sessionStorage.getItem('user_id');
  }

  checkParams(): Observable<boolean> {
    return this.route.queryParams.pipe(
      map(params => {
        let countryId = params['nyx'];
        let userId = params['nyz'];
        console.log(countryId, userId);
        return countryId !== undefined && userId !== undefined;
      })
    );
  }

  public changeStatus(input: any): Observable<any> {
    return from(this.axiosInstance.post('changeStatus', input).then(
      res => {
        return res.data
      }
    ))
  }

}
