<div style="background: #707070;height: 100px;" id="footer">
  <div class="d-flex flex-column flex-md-row h-100 p-4 pt-0 pb-0">
    <div class="d-flex  w-100 justify-content-start align-items-center gap-3 gap-sm-5 ">
      <mat-icon svgIcon="mv-login" class="footer-icon-height"></mat-icon>

      <div class="d-flex flex-row gap-2">
        <div class="d-flex justify-content-center flex-column gap-1 gap-sm-3">
          <a href="mailto:sales@amshuhu.com" class="support-mail-text">{{"support@mvaahna.com"}}</a>
          <div class="d-flex justify-content-between">
            <a href="/assets/privacy_policy.html" target="_blank" class="custom-tandc-txt"
              style="color: #FFFFFF;cursor:pointer;text-decoration: none;">Privacy Policy</a>
            <a href="/assets/privacy_policy.html" target="_blank" class="custom-tandc-txt"
              style="color: #FFFFFF;cursor:pointer;text-decoration: none; padding-left: 15px;">Terms & Conditions</a>
          </div>
        </div>
        <!-- <div>
          <p class="m-0 bottom-banner-txt">Download Garage App (MVaaHna)</p>
          <div class="d-flex custom-layout">
            <div class="me-2">
              <a href="https://play.google.com/store/apps/details?id=com.isteer.vaahna" class="no-lightbox">
                <img loading="lazy" decoding="async" src="/assets/play_store.svg"
                  class="attachment-large size-large wp-image-81 link-img" alt=""> </a>
            </div>
            <div>
              <a href="https://apps.apple.com/in/app/vaahna/id6468909283" class="no-lightbox">
                <img loading="lazy" decoding="async" src="/assets/app_store.svg"
                  class="attachment-large size-large wp-image-94 link-img" alt=""> </a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div
      class="social-media-icons d-flex w-100 justify-content-sm-end justify-content-end align-items-center mt-1 mt-sm-0 mb-1 mb-sm-0">
      <a href="https://www.facebook.com/profile.php?id=61553447756984&mibextid=ZbWKwL" target="_blank"><i
          class="fab fa-facebook-f"></i></a>
      <a href="https://www.linkedin.com" target="_blank"><i class="fab fa-linkedin-in"></i></a>
      <a href="https://www.instagram.com/mvaahna?utm_source=qr&igsh=dmV4ZDdvNXRkYnU2" target="_blank"><i
          class="fab fa-instagram"></i></a>
      <!-- <a href="#"><i class="fab fa-twitter"></i></a> -->
      <a href="https://youtube.com/@mvaaHna?si=j0bNkBN9pHFnkPTU" target="_blank"><i class="fab fa-youtube"></i></a>
    </div>
  </div>

</div>
