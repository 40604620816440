import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { from, map, Observable } from 'rxjs';
import axios, { AxiosInstance } from 'axios'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  axiosInstance: AxiosInstance;
  userData: any
  loginData!: any;
  constructor() {
    const userdata = sessionStorage.getItem('userData')
    if (userdata) {
      this.userData = JSON.parse(userdata)
    }

    this.axiosInstance = axios.create({
      baseURL: environment.BaseUrl,
      headers: {
        'Content-Type': 'application/json',
        'app_platform': 'mvaahna',
        // 'user_id': this.userData.id.toString(),
        // 'company_id': this.userData.company_id.toString(),
        // 'country_id': this.userData.country_id.toString()
      }
    });
  }
  tabResize!: () => void;
  scrollHeight!: () => void;

  public getAllCountries(): Observable<any> {
    return from(this.axiosInstance.post('getAllCountries').then(
      res => {
        return res.data
      }
    ))
  }

  public updatePaypalPayment(input: any): Observable<any> {
    return from(this.axiosInstance.post('updatePaypalPaymentWeb', input).then(
      res => {
        return res.data
      }
    ))
  }

  public fetchCatalogList(input: any): Observable<any> {
    return from(this.axiosInstance.post('fetchCatalog', input).then(
      res => {
        return res.data
      }
    ))
  }

  public fetchMechanicData(input: any): Observable<any> {
    return from(this.axiosInstance.post("fetchMechanicData", input).then(
      res => {
        return res.data
      }
    ))
  }

  public getOTP(input: any): Observable<any> {
    return from(this.axiosInstance.post('login', input).then(
      res => {
        return res.data
      }
    ))
  }

  public getCustOTP(input: any): Observable<any> {
    return from(this.axiosInstance.post('customerOTP', input).then(
      res => {
        return res.data
      }
    ))
  }

  public checkVerificationCode(input: any): Observable<any> {
    return from(this.axiosInstance.post('checkVerificationCode', input).then(
      res => {
        return res.data
      }
    ))
  }


  public checkEmailSub(input: any): Observable<any> {
    return from(this.axiosInstance.post('checkEmailSub', input).then(
      res => {
        return res.data
      }
    ))
  }

  public vaahnaGmailRegistration(input: any): Observable<any> {
    return from(this.axiosInstance.post('vaahnaGmailRegistration', input).then(
      res => {
        return res.data
      }
    ))
  }


  public createCustomer(input: any): Observable<any> {
    return from(this.axiosInstance.post('createCustomer', input).then(
      res => {
        console.log(res.data.data);
        sessionStorage.clear();
        sessionStorage.setItem("user_id", res.data.data.id);
        sessionStorage.setItem('userData', JSON.stringify(res.data.data[0]))
        this.loginData = res.data.data
        return res.data
      }
    ))
  }


  public emailLogin(input: any): Observable<any> {
    return from(this.axiosInstance.post('emailLogin', input).then(
      res => {
        if (res.data.status != 0) {
          sessionStorage.setItem("user_id", res.data.data.id);
          sessionStorage.setItem('userData', JSON.stringify(res.data.data))
          this.loginData = res.data.data
        }
        return res.data
      }))
  }

  public verifyOTP(input: any): Observable<any> {
    return from(this.axiosInstance.post('verifyOTP', input).then(
      res => {
        console.log(res.data.data);
        if (res.data.data.id) {
          sessionStorage.setItem("user_id", res.data.data.id);
          sessionStorage.setItem('userData', JSON.stringify(res.data.data))
          this.loginData = res.data.data
        }
        return res.data
      }
    ))
  }

  public verify_otp(input: any): Observable<any> {
    return from(this.axiosInstance.post('verify_otp', input).then(
      res => {
        return res.data
      }
    ))
  }

  public changeStatus(input: any): Observable<any> {
    return from(this.axiosInstance.post('changeStatus', input).then(
      res => {
        return res.data
      }
    ))
  }

  public forgotPasswordOTP(input: any): Observable<any> {
    return from(this.axiosInstance.post('forgotPasswordOTP', input).then(
      res => {
        return res.data;
      }
    ))
  }

  public changePassword(input: any): Observable<any> {
    return from(this.axiosInstance.post('changePassword', input).then(
      res => {
        return res.data
      }
    ))
  }


  public saveDemoRequest(input: any): Observable<any> {
    return from(this.axiosInstance.post('saveDemoRequest', input).then(
      res => {
        return res.data
      }
    ))
  }

  public fetchFaqTitles(): Observable<any> {
    return from(this.axiosInstance.post('fetchFaqTitles').then(
      res => {
        return res.data
      }
    ))
  }

  public fetchFaq(input: any): Observable<any> {
    return from(this.axiosInstance.post('fetchFaq', input).then(
      res => {
        return res.data
      }
    ))
  }

  public getSubmechanicCommissionReport(input: any): Observable<any> {
    return from(this.axiosInstance.post('getSubmechanicCommissionReport', input).then(
      res => {
        return res.data
      }
    ))
  }

  public getSelectedSubmechanicCommissionReport(input: any): Observable<any> {
    return from(this.axiosInstance.post('getSelectedSubmechanicCommissionReport', input).then(
      res => {
        return res.data
      }
    ))
  }

  public fetchTrendsData(): Observable<any> {
    return from(this.axiosInstance.post("/management/fetchTrendsData", { filter: 'daily' }).then(
      res => {
        return res.data
      }
    ))
  }

  public getBrands(input: any): Observable<any> {
    return from(this.axiosInstance.post('getVehicleBrands', input).then(
      res => {
        return res.data
      }
    ))
  }

  public getModels(input: any): Observable<any> {
    return from(this.axiosInstance.post('getVehiclemodels', input).then(
      res => {
        return res.data
      }
    ))
  }

  public getfuelType(input: any): Observable<any> {
    return from(this.axiosInstance.post('getfuelType', input).then(
      res => {
        return res.data
      }
    ))
  }

  public getAutomatedMessagesList(): Observable<any> {
    return from(this.axiosInstance.post('getAutomatedMessagesList').then(
      res => {
        return res.data
      }
    ))
  }
}
