import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  displayData: any
  showCancel: boolean = true
  showOk: boolean = true
  ngOnInit(): void {
    this.displayData = this.data
    this.showCancel = (this.data.showCancel != undefined) ? this.data.showCancel : this.showCancel
    this.showOk = (this.data.showOk!=undefined) ?this.data.showOk : this.showOk
  }

  closeDialog(flag: number) {
    this.dialogRef.close((flag == 1) ? true : false)
  }

}
