import { Injectable } from '@angular/core';
import axios, { AxiosInstance } from 'axios';
import { Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class MechanicService {

  axiosInstance: AxiosInstance;
  userData: any
  loginData!: any;
  constructor() {
    const userdata = sessionStorage.getItem('userData')
    if (userdata) {
      this.userData = JSON.parse(userdata)
    }

    this.axiosInstance = axios.create({
      baseURL: environment.BaseUrl,
      headers: {
        'Content-Type': 'application/json',
        'app_platform': 'mvaahna',
        'user_id': this.userData.id.toString(),
        'company_id': this.userData.company_id.toString(),
        'country_id': this.userData.country_id.toString()
      }
    });
  }

  public getCustomerDetails(): Observable<any> {
    return from(this.axiosInstance.post('getCustomerDetails').then(
      res => {
        return res.data
      }
    ))
  }

  public getvehicleJobcard(input: any): Observable<any> {
    return from(this.axiosInstance.post('getvehicleJobcard', input).then(
      res => {
        return res.data
      }
    ))
  }

  public getJobcardById(input: any): Observable<any> {
    return from(this.axiosInstance.post('getJobcardById', input).then(
      res => {
        return res.data
      }
    ))
  }

  public updatePendingAmount(input: any): Observable<any> {
    return from(this.axiosInstance.post('updatePendingAmount', input).then(
      res => {
        return res.data
      }
    ))
  }

  public updateMconnectJobCardStatus(input: any): Observable<any> {
    return from(this.axiosInstance.post('updateMconnectJobCardStatus', input).then(
      res => {
        return res.data
      }
    ))
  }

  public addMconnectCustomer(input: any): Observable<any> {
    return from(this.axiosInstance.post('addMconnectCustomer', input).then(
      res => {
        return res.data
      }
    ))
  }

  public getSingleCustomerDetail(input: any): Observable<any> {
    return from(this.axiosInstance.post('getSingleCustomerDetail', input).then(
      res => {
        return res.data
      }
    ))
  }
  public create_jobcardbyOwner(input: any): Observable<any> {
    return from(this.axiosInstance.post('create_jobcardbyOwner', input).then(
      res => {
        return res.data
      }
    ))
  }

  public getAllSubmechanics(input: any): Observable<any> {
    return from(this.axiosInstance.post('getAllSubmechanics', input).then(
      res => {
        return res.data
      }
    ))
  }

  public fetchGarageService(input: any): Observable<any> {
    return from(this.axiosInstance.post('fetchGarageService', input).then(
      res => {
        return res.data
      }
    ))
  }

  public addService(input: any): Observable<any> {
    return from(this.axiosInstance.post('addShopService', input).then(
      res => {
        return res.data
      }
    ))
  }

  public updateMconnectMechanic(input: any): Observable<any> {
    return from(this.axiosInstance.post('updateMconnectMechanic', input).then(
      res => {
        return res.data
      }
    ))
  }


  public referralLog(input: any): Observable<any> {
    return from(this.axiosInstance.post('referralLog', input).then(
      res => {
        return res.data
      }
    ))
  }

  public fetchServiceRemainder(input: any): Observable<any> {
    return from(this.axiosInstance.post('fetchServiceRemainder', input).then(
      res => {
        return res.data
      }
    ))
  }

  public updateServiceStatus(input: any): Observable<any> {
    return from(this.axiosInstance.post('updateServiceStatus', input).then(
      res => {
        return res.data
      }
    ))
  }
  public getJobcardReport(input: any): Observable<any> {
    return from(this.axiosInstance.post('getJobcardReport', input).then(
      res => {
        return res.data
      }
    ))
  }

  public getDashBoardData(input: any): Observable<any> {
    return from(this.axiosInstance.post('getDashBoardData', input).then(
      res => {
        return res.data
      }
    ))
  }

  public addMonthlyTarget(input: any): Observable<any> {
    return from(this.axiosInstance.post('addMonthlyTarget', input).then(
      res => {
        return res.data
      }
    ))
  }

  public getPendingPayment(input: any): Observable<any> {
    return from(this.axiosInstance.post('getPendingPayment', input).then(
      res => {
        return res.data
      }
    ))
  }


  public addSubMechanic(input: any): Observable<any> {
    return from(this.axiosInstance.post('addSubMechanic', input).then(
      res => {
        return res.data
      }
    ))
  }

  public getSubMechanicRoles(input: any): Observable<any> {
    return from(this.axiosInstance.post('getSubMechanicRoles', input).then(
      res => {
        return res.data
      }
    ))
  }

  public addSubmechanicRoles(input: any): Observable<any> {
    return from(this.axiosInstance.post('addSubmechanicRoles', input).then(
      res => {
        return res.data
      }
    ))
  }

  public getSubMechanicData(input: any): Observable<any> {
    return from(this.axiosInstance.post('getSubMechanicData', input).then(
      res => {
        return res.data
      }
    ))
  }

  public applyGst(input: any): Observable<any> {
    return from(this.axiosInstance.post('applyGst', input).then(
      res => {
        return res.data
      }
    ))
  }

  public deletejobcardimage(input: any): Observable<any> {
    return from(this.axiosInstance.post('deletejobcardimage', input).then(
      res => {
        return res.data
      }
    ))
  }

  public fetchNotifications(input: any): Observable<any> {
    return from(this.axiosInstance.post('fetchNotifications', input).then(
      res => {
        return res.data
      }
    ))
  }

  public acceptJobcard(input: any): Observable<any> {
    return from(this.axiosInstance.post('acceptJobcard', input).then(
      res => {
        return res.data
      }
    ))
  }


  public updateCustomerDetails(input: any): Observable<any> {
    return from(this.axiosInstance.post('updateCustomerDetails', input).then(
      res => {
        return res.data
      }
    ))
  }

  public reopenJc(input: any): Observable<any> {
    return from(this.axiosInstance.post('updateStatus', input).then(
      res => {
        return res.data
      }
    ))
  }

  public saveMechanicDetailsProgress(input: any): Observable<any> {
    return from(this.axiosInstance.post('saveMechanicDetailsProgress', input).then(
      res => {
        return res.data
      }
    ))
  }

  public webUsagelog(): Observable<any> {
    return from(this.axiosInstance.post('webUsagelog').then(
      res => {
        return res.data
      }
    ))
  }
}
