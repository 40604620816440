<!-- <div>
  <div class="position-relative">
    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-outline-secondary" [mat-dialog-close]>x
      </button>
    </div>
  </div>
  <div class="d-flex flex-column pe-1 pt-4">
    <p class="fw-bold custom-font">For booking, Please download our mobile application</p>
    <div class="d-flex flex-column flex-sm-row justify-content-center custom-padding">
      <div class=" me-2">
        <a href="https://play.google.com/store/apps/details?id=com.isteer.vaahna" class="no-lightbox">
          <img loading="lazy" decoding="async" src="/assets/play_store.svg"
            class="attachment-large size-large wp-image-81 link-img" alt=""> </a>
      </div>
      <div>
        <a href="https://apps.apple.com/in/app/vaahna/id6468909283" class="no-lightbox">
          <img loading="lazy" decoding="async" src="/assets/app_store.svg"
            class="attachment-large size-large wp-image-94 link-img" alt=""> </a>
      </div>
    </div>
  </div>
</div> -->

<div class="overflow-hidden" id="dialog-box" #dialogBox>
  <div *ngIf="login_step==1">
    <div class="d-flex justify-content-center  align-items-center">
      <div>
        <mat-icon svgIcon="mv-login" style="width: 170px;height: 100px"></mat-icon>
      </div>
    </div>
    <div>
      <div>
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" style="padding: 16px;"
          [config]="slideConfig ">
          <div ngxSlickItem *ngFor="let x of slidesStore" class="slide img-container">
            <div class="w-100 d-flex justify-content-center">
              <img [src]=" x.src " class="img-fluid">
            </div>
            <div class="mt-4 ms-1 me-1">
              <p class="title-txt">{{ x.title }}</p>
            </div>
            <div class="mt-2 ms-5 me-5">
              <p class="desc-txt">{{x.desc}}</p>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
    <div>
      <div class="d-flex col-12">
        <div class="d-flex justify-content-center align-items-center col-12">
          <button class="btn reg-btn flex-fill ms-5 ps-2 me-5 pe-2" (click)="changeStep(2,'')" id="focus">
            <p class="m-0">Login</p>
          </button>
        </div>
        <!-- <div class="d-flex justify-content-center align-items-center col-2">or
        </div>
        <div class="d-flex justify-content-center align-items-center col-5">
          <button class="btn reg-btn" (click)="changeStep(3,'')">
            <p class="m-0">Register</p>
          </button>
        </div> -->
      </div>
      <!-- <div class="d-flex col-12 ">
        <div class="d-flex justify-content-center align-items-center col-5">
        </div>
        <div class="d-flex justify-content-center align-items-center col-2">
          <p class="m-0 d-flex align-items-center">or</p>
        </div>
        <div class="d-flex justify-content-center align-items-center col-5">
        </div>
      </div>
      <div class="d-flex col-12 mb-4">
        <div class="d-flex justify-content-center align-items-center col-5">
          <div id="google-btn"></div>
        </div>
        <div class="d-flex justify-content-center align-items-center col-2">
          <p class="m-0 d-flex align-items-center"></p>
        </div>
        <div class="d-flex justify-content-center align-items-center col-5">

          <button class="apple-btn">
            <mat-icon svgIcon="apple" class="d-block" style="height: 32px;width: 32px;"></mat-icon></button>
        </div>
      </div> -->
    </div>
    <!-- <div class="d-flex justify-content-center align-items-center">
      <a (click)="close()">
        <p class="m-0 guest-txt">Browse as <span>Guest</span></p>
      </a>

    </div> -->
  </div>
  <div class="top-bar-theme" *ngIf="login_step==2">
    <div id="top_bar" class="col-12 d-flex p-4">
      <div class=" d-flex flex-column justify-content-between col-6">
        <mat-icon style="color: #FFFFFF;" (click)="changeStep(1,'')">keyboard_arrow_left</mat-icon>
        <div>
          <p class="m-0 top-bar-title">Login</p>
          <p class="m-0 top-bar-desc">Welcome back! Enter your mobile number to proceed</p>
        </div>
      </div>
      <div class="col-6 text-center d-flex">
        <!-- <img src="/assets/reg-screen-icon1.svg" style="width: 152px;height: 158px;"> -->
        <img src="assets/reg-screen-icon1.svg" class="img-fluid">
      </div>
    </div>
    <div id="work_area" class="d-flex flex-column justify-content-between ounded-top-5"
      style="background-color: #FFFFFF;">
      <div class="container mt-5 d-flex flex-column justify-content-center"
        *ngIf="!(showNumberField || showEmailField)">
        <p class="fs-6 fw-bold">Please Select Country</p>
        <div class="dropdown  w-100">
          <!-- <button class="btn dropdown-toggle" type="button" id="colorDropdownButton" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{ selectedCountry || 'Select a country' }}
          </button> -->
          <div class="card m-2" style="cursor: default;" id="colorDropdownButton" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="card-body">
              <div class="d-flex justify-content-center align-items-center">
                <div>
                  <!-- <span class="flag-icon" ngClass="flag-icon-in"></span> -->
                  <span class="p-3">{{ selectedCountry || 'Select a country' }}</span>
                </div>
              </div>
            </div>
          </div>
          <ul class="dropdown-menu w-50 overflow-scroll m-3 bg-light" style="height: 150px;"
            aria-labelledby="colorDropdownButton">
            <li *ngFor="let x of countries" (click)="onSelect(x)" class="d-flex ms-2 mt-2" style="cursor: pointer;">
              <span class="flag-icon dropdown-item" ngClass="{{'flag-icon-' + x.country_code.toLowerCase()}}"></span>
              <span class="dropdown-item">{{ x.name }}</span>
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="showNumberField">
        <label class="ms-4 me-4 mt-3 fs-6 fw-bold">Please enter your mobile number</label>
        <div class="input-group ms-4 me-4 mt-3 custom-search-width" [formGroup]="mobileLogin">
          <span class="input-group-text"><img style="max-width: 40px;cursor: pointer;"
              src="assets/mechanic-app/image/phone.svg" /></span>
          <span class="input-group-text">{{selectedCountryObj.code}}</span>
          <input type="text" class="form-control custom-input border-start-0  custom-search-box text-start"
            placeholder="Mobile Number" formControlName="mobile_number" aria-label="Search"
            aria-describedby="basic-addon2">
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="mobileLogin.get('mobile_number')?.hasError('required') && mobileLogin.get('mobile_number')?.touched">
          <p class="m-0 fs-5 text-danger">Mobile Number is required.</p>
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="mobileLogin.get('mobile_number')?.hasError('pattern') && mobileLogin.get('mobile_number')?.touched">
          <p class="m-0 fs-5 text-danger">In-valid Mobile Number.</p>
        </div>
      </div>

      <div *ngIf="showEmailField" [formGroup]="emailLogin">
        <div class="input-group ms-4 me-4 mt-3 custom-search-width">
          <input type="text" class="form-control custom-input  custom-search-box text-start" placeholder="Email ID"
            formControlName="email" aria-label="Search" aria-describedby="basic-addon2">
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="emailLogin.get('email')?.hasError('required') && emailLogin.get('email')?.touched">
          <p class="m-0 fs-5 text-danger">Email ID is required.</p>
        </div>
        <div class="input-group ms-4 me-4 mt-3 custom-search-width">
          <input type="password" class="form-control custom-input  custom-search-box text-start" placeholder="Password"
            formControlName="password" aria-label="Search" aria-describedby="basic-addon2">
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="emailLogin.get('password')?.hasError('required') && emailLogin.get('password')?.touched">
          <p class="m-0 fs-5 text-danger">Password is required.</p>
        </div>
        <div class="mt-2 text-end me-5">
          <a style="color:#245069;font-weight:500;text-decoration: underline;cursor: pointer;"
            (click)="changeStep(5,'')">Forgot Password ?</a>
        </div>
      </div>


      <div *ngIf="showNumberField" class="d-flex justify-content-center align-items-center mt-2">
        <button class="btn reg-btn" [disabled]="mobileLogin.invalid" (click)="getOTP()">Continue</button>
      </div>
      <div *ngIf="showEmailField" class="d-flex justify-content-center align-items-center mt-2">
        <button class="btn reg-btn" [disabled]="emailLogin.invalid" (click)="getOTP()">Continue</button>
      </div>
    </div>


  </div>
  <div class="top-bar-theme" *ngIf="login_step==3">
    <div id="top_bar" class="col-12 d-flex p-4">
      <div class=" d-flex flex-column justify-content-between col-6">
        <mat-icon style="color: #FFFFFF;" (click)="changeStep(1,'')">keyboard_arrow_left</mat-icon>
        <div>
          <p class="m-0 top-bar-title">Register</p>
          <p class="m-0 top-bar-desc">Welcome to VaaHna! Let’s create your account</p>
        </div>
      </div>
      <div class="col-6 text-center d-flex">
        <!-- <img src="/assets/reg-screen-icon1.svg" style="width: 152px;height: 158px;"> -->
        <img src="/assets/reg-screen-icon1.svg" class="img-fluid">
      </div>
    </div>
    <div id="work_area" class="d-flex flex-column justify-content-between rounded-top rounded-top-5"
      style="background-color: #FFFFFF;">

      <div class="container mt-5 d-flex flex-column justify-content-center"
        *ngIf="!(showNumberField || showEmailField)">
        <p class="fs-6 fw-bold">Please Select Country</p>
        <div class="dropdown  w-100">
          <div class="card m-2" style="cursor: default;" id="colorDropdownButton" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="card-body">
              <div class="d-flex justify-content-center align-items-center">
                <div>
                  <span class="p-3">{{ selectedCountry || 'Select a country' }}</span>
                </div>
              </div>
            </div>
          </div>
          <ul class="dropdown-menu w-50 overflow-scroll m-3" style="height: 150px;"
            aria-labelledby="colorDropdownButton">
            <li *ngFor="let x of countries" (click)="onSelect(x)" class="d-flex ms-2 mt-2" style="cursor: pointer;">
              <span class="flag-icon dropdown-item" ngClass="{{'flag-icon-' + x.country_code.toLowerCase()}}"></span>
              <span class="dropdown-item">{{ x.name }}</span>
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="showNumberField" [formGroup]="mobileRegister">
        <div class="input-group ms-4 me-4 mt-4 custom-search-width">
          <span class="input-group-text"><img style="max-width: 40px;cursor: pointer;"
              src="/assets/user_avatar.svg" /></span>
          <input type="text" class="form-control custom-input border-start-0  custom-search-box text-start"
            placeholder="Name" formControlName="name" aria-label="Search" aria-describedby="basic-addon2">
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="mobileRegister.get('name')?.hasError('required') && mobileRegister.get('name')?.touched">
          <p class="m-0 fs-5 text-danger">Name is required.</p>
        </div>
        <div class="input-group ms-4 me-4 mt-3 custom-search-width">
          <span class="input-group-text"><img style="max-width: 40px;cursor: pointer;"
              src="/assets/mobile.svg" /></span>
          <span class="input-group-text">{{selectedCountryObj.code}}</span>
          <input type="text" class="form-control custom-input border-start-0  custom-search-box text-start"
            placeholder="Mobile Number" formControlName="mobile_number" aria-label="Search"
            aria-describedby="basic-addon2">
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="mobileRegister.get('mobile_number')?.hasError('required') && mobileRegister.get('mobile_number')?.touched">
          <p class="m-0 fs-5 text-danger">Mobile Number is required.</p>
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="mobileRegister.get('mobile_number')?.hasError('pattern') && mobileRegister.get('mobile_number')?.touched">
          <p class="m-0 fs-5 text-danger">Invalid Mobile Number</p>
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="mobileRegister.get('mobile_number')?.hasError('maxLength') && mobileRegister.get('mobile_number')?.touched">
          <p class="m-0 fs-5 text-danger"> Mobile Number cannot exceed 10 characters.</p>
        </div>
      </div>
      <div *ngIf="showEmailField" [formGroup]="emailRegister">
        <div class="input-group ms-4 me-4 mt-3 custom-search-width">

          <input type="text" class="form-control custom-input  custom-search-box text-start" placeholder="Email ID"
            formControlName="email" aria-label="Search" aria-describedby="basic-addon2" autocomplete="off">
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="emailRegister.get('email')?.hasError('required') && emailRegister.get('email')?.touched">
          <p class="m-0 fs-5 text-danger">Email ID is required.</p>
        </div>
        <div class="input-group ms-4 me-4 mt-3 custom-search-width">
          <input type="text" class="form-control custom-input  custom-search-box text-start" placeholder="Name"
            formControlName="name" aria-label="Search" aria-describedby="basic-addon2" autocomplete="off">
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="emailRegister.get('name')?.hasError('required') && emailRegister.get('name')?.touched">
          <p class="m-0 fs-5 text-danger">Name is required.</p>
        </div>
        <div class="input-group ms-4 me-4 mt-3 custom-search-width">
          <span class="input-group-text">{{selectedCountryObj.code}}</span>
          <input type="text" class="form-control custom-input  custom-search-box text-start" placeholder="Mobile Number"
            formControlName="phone" aria-label="Search" aria-describedby="basic-addon2" autocomplete="off">
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="emailRegister.get('phone')?.hasError('required') && emailRegister.get('phone')?.touched">
          <p class="m-0 fs-5 text-danger">Mobile Number is required.</p>
        </div>
        <div class="input-group ms-4 me-4 mt-3 custom-search-width">
          <input type="password" class="form-control custom-input  custom-search-box text-start"
            placeholder="New Password" formControlName="password" aria-label="Search" aria-describedby="basic-addon2"
            autocomplete="off">
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="emailRegister.get('password')?.hasError('required') && emailRegister.get('password')?.touched">
          <p class="m-0 fs-5 text-danger">Password is required.</p>
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="emailRegister.get('password')?.hasError('pattern') && emailRegister.get('password')?.touched">
          <p class="m-0 fs-5 text-danger">Password must contain at least one uppercase letter, one number, and one
            special character.</p>
        </div>
        <div class="input-group ms-4 me-4 mt-3 custom-search-width">
          <input type="password" class="form-control custom-input  custom-search-box text-start"
            placeholder="Re enter Password" formControlName="confirmPassword" aria-label="Search"
            aria-describedby="basic-addon2">
        </div>
        <div class="ms-4 me-4 mt-3" *ngIf="emailRegister.hasError('mismatch')">
          <p class="m-0 fs-5 text-danger">Passwords do not match.</p>
        </div>


      </div>
      <div *ngIf="showNumberField" class="d-flex justify-content-center align-items-center">
        <button class="btn reg-btn" [disabled]="mobileRegister.invalid" (click)="register()">Continue</button>
      </div>
      <div *ngIf="showEmailField" class="d-flex justify-content-center align-items-center">
        <button class="btn reg-btn" [disabled]="emailRegister.invalid" (click)="register()">Continue</button>
      </div>
    </div>


  </div>
  <div class="top-bar-theme" *ngIf="login_step==4">
    <div id="top_bar" class="col-12 d-flex p-4">
      <div class=" d-flex flex-column justify-content-between col-6">
        <mat-icon style="color: #FFFFFF;" (click)="changeStep(1,'')">keyboard_arrow_left</mat-icon>
        <div>
          <p class="m-0 top-bar-title">User Verification</p>
          <p class="m-0 top-bar-desc">Enter OTP</p>
        </div>
      </div>
      <div class="col-6 text-center d-flex">
        <!-- <img src="/assets/reg-screen-icon1.svg" style="width: 152px;height: 158px;"> -->
        <img src="/assets/reg-screen-icon1.svg" class="img-fluid">
      </div>
    </div>
    <div id="work_area" class="d-flex flex-column justify-content-between rounded-top rounded-top-5"
      style="background-color: #FFFFFF;">
      <div *ngIf="showNumberField">
        <div class="mt-4 text-center" *ngIf="!newReg">
          <p>Verification code has been sent to
            {{mobileLogin.value.mobile_number}} <a (click)="changeStep(1,'')"
              style="color:#245069;font-weight:500;text-decoration: underline;cursor: pointer;">Edit</a></p>
        </div>
        <div class="mt-4 text-center" *ngIf="newReg">
          <p>Verification code has been sent to
            {{mobileRegister.value.phone}} <a (click)="changeStep(1,'')"
              style="color:#245069;font-weight:500;text-decoration: underline;cursor: pointer;">Edit</a></p>
        </div>
        <div class="otp-container d-flex flex-column">
          <div>
            <div *ngFor="let index of [0, 1, 2, 3]; let i = index" class="otp-input">
              <input type="text" maxlength="1" (keyup)="onKeyUp($event, i)" [(ngModel)]="otp[i]"
                [ngClass]="{ 'invalid': otpInvalid && !otp[i] }" />
            </div>
          </div>
          <p *ngIf="otpInvalid" class="error-message">Please enter a valid OTP.</p>
        </div>
        <div class="text-center pt-3">
          <p>Didn't recieve the code ?</p>
          <div class="d-flex justify-content-evenly">
            <a class="resend-text" (click)="resend()" [class.disabled]="disabled">Resend</a>
            <div class="d-flex">
              <!-- <app-count-down [startTime]="startTime" (countdownFinished)="onCountdownFinished()"></app-count-down> -->
              <div>{{ displayTime}}</div>s
            </div>
          </div>
          <!-- <countdown [config]="{leftTime: 30}"></countdown> -->
        </div>
      </div>

      <div *ngIf="showEmailField">
        <div class="mt-4 text-center">
          <p>Verification code has been sent to
            {{emailLogin.value.email||emailRegister.value.email}} <a (click)="changeStep(1,'')"
              style="color:#245069;font-weight:500;text-decoration: underline;cursor: pointer;">Edit</a></p>
        </div>
        <div class="otp-container d-flex flex-column">
          <div>
            <div *ngFor="let index of [0, 1, 2, 3, 4, 5]; let i = index" class="otp-input">
              <input type="text" maxlength="1" (keyup)="onKeyUp($event, i)" [(ngModel)]="otp[i]"
                [ngClass]="{ 'invalid': otpInvalid && !otp[i] }" />
            </div>
          </div>
          <p *ngIf="otpInvalid" class="error-message">Please enter a valid OTP.</p>
        </div>
        <div class="text-center pt-3">
          <p>Didn't recieve the code ?</p>
          <div class="d-flex justify-content-evenly">
            <a class="resend-text" (click)="resend()" [class.disabled]="disabled">Resend</a>
            <div class="d-flex">
              <!-- <app-count-down [startTime]="startTime" (countdownFinished)="onCountdownFinished()"></app-count-down> -->
              <div>{{ displayTime}}</div>s
            </div>
          </div>
          <!-- <countdown [config]="{leftTime: 30}"></countdown> -->
        </div>
      </div>
      <div *ngIf="!forgotPasswordFlag" class="d-flex justify-content-center align-items-center">
        <button class="btn reg-btn" (click)="verifyOTP()" [ngClass]="(otpInvalid) ? 'disable-verify':''"
          [disabled]="otpInvalid">Verify</button>
      </div>
      <div *ngIf="forgotPasswordFlag" class="d-flex justify-content-center align-items-center">
        <button class="btn reg-btn" (click)="forgotPasswordOTP('verify')" [ngClass]="(otpInvalid) ? 'disable-verify':''"
          [disabled]="otpInvalid">Verify</button>
      </div>
    </div>
  </div>

  <div class="top-bar-theme" *ngIf="login_step==5">
    <div id="top_bar" class="col-12 d-flex p-4">
      <div class=" d-flex flex-column justify-content-between col-6">
        <mat-icon style="color: #FFFFFF;" (click)="changeStep(1,'')">keyboard_arrow_left</mat-icon>
        <div>
          <p class="m-0 top-bar-title">Forgot Password</p>
          <!-- <p class="m-0 top-bar-desc">Welcome to VaaHna! Let’s create your account</p> -->
        </div>
      </div>
      <div class="col-6 text-center d-flex">
        <!-- <img src="/assets/reg-screen-icon1.svg" style="width: 152px;height: 158px;"> -->
        <img src="/assets/reg-screen-icon1.svg" class="img-fluid">
      </div>
    </div>
    <div id="work_area" class="d-flex flex-column justify-content-between rounded-top rounded-top-5"
      style="background-color: #FFFFFF;">

      <div [formGroup]="forgotPassword">
        <div class="input-group ms-4 me-4 mt-3 custom-search-width">
          <input type="text" class="form-control custom-input  custom-search-box text-start" placeholder="Email ID"
            formControlName="email" aria-label="Search" aria-describedby="basic-addon2" autocomplete="off">
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="forgotPassword.get('email')?.hasError('required') && forgotPassword.get('email')?.touched">
          <p class="m-0 fs-5 text-danger">Email ID is required.</p>
        </div>
        <ng-container *ngIf="showResetPassword">
          <div class="input-group ms-4 me-4 mt-3 custom-search-width">
            <input type="password" class="form-control custom-input  custom-search-box text-start"
              placeholder="New Password" formControlName="password" aria-label="Search" aria-describedby="basic-addon2"
              autocomplete="off">
          </div>
          <div class="ms-4 me-4 mt-3"
            *ngIf="forgotPassword.get('password')?.hasError('required') && forgotPassword.get('password')?.touched">
            <p class="m-0 fs-5 text-danger">Password is required.</p>
          </div>
          <div class="ms-4 me-4 mt-3"
            *ngIf="forgotPassword.get('password')?.hasError('pattern') && forgotPassword.get('password')?.touched">
            <p class="m-0 fs-5 text-danger">Password must contain at least one uppercase letter, one number, and one
              special character.</p>
          </div>
          <div class="input-group ms-4 me-4 mt-3 custom-search-width">
            <input type="password" class="form-control custom-input  custom-search-box text-start"
              placeholder="Re enter Password" formControlName="confirmPassword" aria-label="Search"
              aria-describedby="basic-addon2">
          </div>
          <div class="ms-4 me-4 mt-3" *ngIf="forgotPassword.hasError('mismatch')">
            <p class="m-0 fs-5 text-danger">Passwords do not match.</p>
          </div>
        </ng-container>
      </div>
      <div class="d-flex justify-content-center align-items-center" *ngIf="showResetPassword">
        <button class="btn reg-btn" [disabled]="forgotPassword.invalid"
          (click)="forgotPasswordOTP('reset')">Continue</button>
      </div>
      <div class="d-flex justify-content-center align-items-center" *ngIf="!showResetPassword">
        <button class="btn reg-btn" [disabled]="forgotPassword.invalid"
          (click)="forgotPasswordOTP('otp')">Continue</button>
      </div>
    </div>


  </div>

  <div class="top-bar-theme" *ngIf="login_step==6">
    <div id="top_bar" class="col-12 d-flex p-4">
      <div class=" d-flex flex-column justify-content-between col-6">
        <mat-icon style="color: #FFFFFF;" (click)="changeStep(1,'')">keyboard_arrow_left</mat-icon>
        <div>
          <p class="m-0 top-bar-title">Register</p>
          <p class="m-0 top-bar-desc">Welcome to VaaHna! Let’s create your account</p>
        </div>
      </div>
      <div class="col-6 text-center d-flex">
        <!-- <img src="/assets/reg-screen-icon1.svg" style="width: 152px;height: 158px;"> -->
        <img src="/assets/reg-screen-icon1.svg" class="img-fluid">
      </div>
    </div>
    <div id="work_area" class="d-flex flex-column justify-content-between rounded-top rounded-top-5"
      style="background-color: #FFFFFF;">

      <div class="container mt-5 d-flex flex-column justify-content-center"
        *ngIf="!(showNumberField || showEmailField)">
        <p class="fs-6 fw-bold">Please Select Country</p>
        <div class="dropdown  w-100">
          <div class="card m-2" style="cursor: default;" id="colorDropdownButton" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="card-body">
              <div class="d-flex justify-content-center align-items-center">
                <div>
                  <span class="p-3">{{ selectedCountry || 'Select a country' }}</span>
                </div>
              </div>
            </div>
          </div>
          <ul class="dropdown-menu w-50 overflow-scroll m-3" style="height: 150px;"
            aria-labelledby="colorDropdownButton">
            <li *ngFor="let x of countries" (click)="onSelect(x)" class="d-flex ms-2 mt-2" style="cursor: pointer;">
              <span class="flag-icon dropdown-item" ngClass="{{'flag-icon-' + x.country_code.toLowerCase()}}"></span>
              <span class="dropdown-item">{{ x.name }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="(showNumberField || showEmailField)" [formGroup]="gmailRegister">
        <div class="input-group ms-4 me-4 mt-3 custom-search-width">

          <input type="text" class="form-control custom-input  custom-search-box text-start" placeholder="Email ID"
            formControlName="email" aria-label="Search" aria-describedby="basic-addon2" autocomplete="off"
            [readOnly]="true">
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="gmailRegister.get('email')?.hasError('required') && gmailRegister.get('email')?.touched">
          <p class="m-0 fs-5 text-danger">Email ID is required.</p>
        </div>
        <div class="input-group ms-4 me-4 mt-3 custom-search-width">
          <input type="text" class="form-control custom-input  custom-search-box text-start" placeholder="Name"
            formControlName="name" aria-label="Search" aria-describedby="basic-addon2" autocomplete="off">
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="gmailRegister.get('name')?.hasError('required') && gmailRegister.get('name')?.touched">
          <p class="m-0 fs-5 text-danger">Name is required.</p>
        </div>
        <div class="input-group ms-4 me-4 mt-3 custom-search-width">
          <span class="input-group-text">{{selectedCountryObj.code}}</span>
          <input type="text" class="form-control custom-input  custom-search-box text-start" placeholder="Mobile Number"
            formControlName="phone" aria-label="Search" aria-describedby="basic-addon2" autocomplete="off">
        </div>
        <div class="ms-4 me-4 mt-3"
          *ngIf="gmailRegister.get('phone')?.hasError('required') && gmailRegister.get('phone')?.touched">
          <p class="m-0 fs-5 text-danger">Mobile Number is required.</p>
        </div>
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <button class="btn reg-btn" [disabled]="gmailRegister.invalid" (click)="registerViaGmail()">Continue</button>
      </div>
    </div>


  </div>

</div>
