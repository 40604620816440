<div class="row d-flex flex-column flex-sm-row shadow-lg  g-0 bg-light" id="header">
  <div class="col col-sm-4 col-md-6 d-flex justify-content-between align-items-center">
    <mat-icon svgIcon="mv-login" class="header-icon-height"></mat-icon>
    <div *ngIf="login">
      Logged in as user <span [matMenuTriggerFor]="menu"
        style="color:#2C63A1;text-decoration: underline;cursor: pointer;" class="fs-5 fw-bold">{{firstLetter}}</span>
    </div>
    <mat-menu #menu="matMenu">
      <!-- <button mat-menu-item class="text-capitalize"><mat-icon>delete_outline</mat-icon>Delete User
        Account</button> -->
      <button mat-menu-item (click)="openLogin('logout',0)"
        *ngIf="login"><mat-icon>exit_to_app</mat-icon>Logout</button>
    </mat-menu>
  </div>
  <div class="col col-sm-8 col-md-6 d-flex justify-content-end align-items-center" *ngIf="showToolbar">
    <ul class="nav justify-content">
      <ng-container *ngFor="let x of navItem">
        <li class="nav-item">
          <!-- <a class="nav-link" [routerLink]="getRoute(x.route)" (click)="handleNavigation(x.route)">
            <h2 class="nav-text-custom m-0">{{x.label}}</h2>
          </a> -->
          <a class="nav-link" (click)="navigateToPage(x.route)">
            <h2 class="nav-text-custom m-0">{{x.label}}</h2>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
