import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DrawerService } from '../shared/services/drawer.service';
import { MatDrawer } from '@angular/material/sidenav';
import { filter } from 'rxjs';
import { GlobalService } from '../shared/services/global.service';
import { MechanicService } from '../shared/services/mechanic.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchService } from '../shared/services/search.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-mechanic-app',
  templateUrl: './mechanic-app.component.html',
  styleUrls: ['./mechanic-app.component.scss']
})
export class MechanicAppComponent implements OnInit {
  @ViewChild('drawer') drawer!: MatDrawer;
  showToolbarNav: boolean = true;
  showBottomBarNav: boolean = false;
  showMainToolbar: boolean = true;
  childRoutePath: string | undefined;
  jcCreditData: any
  userData: any

  constructor(private router: Router, private route: ActivatedRoute, private renderer: Renderer2, private el: ElementRef, private drawerService: DrawerService, private globalService: GlobalService, private _mechanicService: MechanicService, private loader: NgxSpinnerService, private searchService: SearchService, private dialog: MatDialog) {
    // const childRoutes = this.route.children;

    // childRoutes.forEach(childRoute => {
    //   childRoute.data.subscribe(data => {
    //     console.log('Child Route Data:', data);
    //   });
    // });
    this.drawerService.resize = this.resize.bind(this)
    this.drawerService.setToolbar = this.setToolbar.bind(this)



    this.resize()
    this.renderer.listen('window', 'resize', (event) => {
      this.resize()
    })
    this.drawerService.toggleDrawer$.subscribe(() => {
      this.drawer.toggle();
    });
  }

  customer_id: any = 0;
  searchTerm: string = '';
  sortMethod!: any
  subRoutes = [
    { icon: "assets/mechanic-app/image/jobsinq.svg", label: "Jobs In Q", route: "jobs-in-queue" },
    { icon: "assets/mechanic-app/image/pending.svg", label: "Pending", route: "pending" },
    { icon: "assets/mechanic-app/image/inprogress.svg", label: "In Progress", route: "in-progress" },
    { icon: "assets/mechanic-app/image/completed.svg", label: "Completed", route: "completed" }
  ]

  nav_list = [{ route: ['/mvaahna-mechanic/customer-jobcard', this.customer_id], icon: 'assets/mechanic-app/image/serviceinfo.svg', label: 'Create Jobcard' }, { route: ['/mvaahna-mechanic/customer-jc-info', this.customer_id], icon: 'assets/mechanic-app/image/jobcards.svg', label: 'Job Cards' }, { route: ['/mvaahna-mechanic/customer-profile-info', this.customer_id], icon: 'assets/mechanic-app/image/summary.svg', label: 'Summary' }]

  ngOnInit(): void {
    const userData = sessionStorage.getItem('userData')
    if (userData) {
      this.userData = JSON.parse(userData)
    }
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.getCredits()
      this.saveUsageLog()
      this.customer_id = this.drawerService.getCustomerId()
      this.nav_list = [{ route: ['/mvaahna-mechanic/customer-jobcard', this.customer_id], icon: 'assets/mechanic-app/image/serviceinfo.svg', label: 'Create Jobcard' }, { route: ['/mvaahna-mechanic/customer-jc-info', this.customer_id], icon: 'assets/mechanic-app/image/jobcards.svg', label: 'Job Cards' }, { route: ['/mvaahna-mechanic/customer-profile-info', this.customer_id], icon: 'assets/mechanic-app/image/summary.svg', label: 'Summary' }]
      this.updateChildRoutePath();
    });

    this.updateChildRoutePath();
    this.getCredits()
    this.saveUsageLog()

  }

  onSearchChange() {
    this.searchService.setSearchTerm(this.searchTerm);
  }

  setSortOption(option: string) {
    this.sortMethod = option
    this.searchService.setSort(option)
  }

  goToNotification() {
    this.router.navigate(['/mvaahna-mechanic/notification'])
  }

  updateChildRoutePath(): void {
    const childRoutes = this.route.children;
    // this.resize();

    if (childRoutes.length > 0) {
      const childRoute = childRoutes[0];
      this.childRoutePath = childRoute.snapshot.routeConfig?.path;
      if (this.childRoutePath?.includes('mechanic') || this.childRoutePath?.includes('garage') || this.childRoutePath?.includes('service') || this.childRoutePath?.includes('supplier') || this.childRoutePath?.includes('dashboard') || this.childRoutePath?.includes('payment') || this.childRoutePath?.includes('create-customer') || this.childRoutePath?.includes('jc-detail') || this.childRoutePath?.includes('jc-create') || this.childRoutePath?.includes('jc-complete') || this.childRoutePath?.includes('register-user') || this.childRoutePath?.includes('dent-and') || this.childRoutePath?.includes('notification') || this.childRoutePath?.includes('booking') || this.childRoutePath?.includes('edit-customer')) {
        this.resize()
        this.showToolbarNav = false
        this.showBottomBarNav = false
        this.showMainToolbar = false
      } else if (this.childRoutePath?.includes('customer')) {
        this.resize()
        this.showMainToolbar = false
        this.showToolbarNav = false
        this.showBottomBarNav = true
      } else {
        this.resize()
        this.showMainToolbar = true
        this.showToolbarNav = true
        this.showBottomBarNav = false
      }

      if (this.childRoutePath?.includes('customer-jc-info')) {
        this.globalService.setJcHeader(true);
      } else {
        this.globalService.setJcHeader(false)
      }
    } else {
      this.childRoutePath = undefined;
    }
  }

  toggleSideNav() {
    this.drawerService.toggleDrawer();
  }

  resize() {
    setTimeout(() => {

      let height = window.innerHeight;

      if (this.el.nativeElement.querySelector('#workArea') != null && this.el.nativeElement.querySelector('#topLayout') && this.el.nativeElement.querySelector('#mainToolBar') != null) {
        this.el.nativeElement.querySelector('#workArea').style.height = height - this.el.nativeElement.querySelector('#mainToolBar').offsetHeight + 'px'

        height = height - this.el.nativeElement.querySelector('#mainToolBar').offsetHeight - this.el.nativeElement.querySelector('#topLayout').offsetHeight

        if (this.el.nativeElement.querySelector('#routingArea') != null) {
          this.el.nativeElement.querySelector('#routingArea').style.height = height + 'px'
          this.el.nativeElement.querySelector('#routingArea').style.overflowY = 'auto'
          this.el.nativeElement.querySelector('#routingArea').style.overflowX = 'hidden'
        }

      } else {
        this.el.nativeElement.querySelector('#workArea').style.height = height + 'px'
        this.el.nativeElement.querySelector('#routingArea').style.height = (this.el.nativeElement.querySelector('#bottomNavBar') != null) ? height - this.el.nativeElement.querySelector('#bottomNavBar').offsetHeight + 'px' : height + 'px'
      }
      // this.setToolbar();
    }, 1);
  }

  setToolbar() {
    setTimeout(() => {

      let height = this.el.nativeElement.querySelector('#routingArea').offsetHeight;


      if (this.el.nativeElement.querySelector('#subRouteArea') != null) {
        let buttonContainerHeight = 0
        let secondaryToolbar = 0
        if (this.el.nativeElement.querySelector('#SaveButton') != null) {
          buttonContainerHeight = this.el.nativeElement.querySelector('#SaveButton').offsetHeight
        }
        if (this.el.nativeElement.querySelector('#secondaryToolbar') != null) {
          secondaryToolbar = this.el.nativeElement.querySelector('#secondaryToolbar').offsetHeight
        }

        this.el.nativeElement.querySelector('#subRouteArea').style.height = (this.el.nativeElement.querySelector('#bottomNavBar') != null) ? height - this.el.nativeElement.querySelector('#bottomNavBar').offsetHeight - buttonContainerHeight - secondaryToolbar + 'px' : height - buttonContainerHeight - secondaryToolbar + 'px'
        this.el.nativeElement.querySelector('#subRouteArea').style.overflowY = 'auto'
      }


    }, 1);
  }

  getCredits() {

    this.loader.show()
    const input = { owner_id: this.userData.id }

    this._mechanicService.getJobcardReport(input).subscribe((res: any) => {
      if (res.status == 1) {
        this.jcCreditData = res.data[0]
        this.loader.hide()
      }
    })

  }

  saveUsageLog() {
    this._mechanicService.webUsagelog()
  }

  addCredit() {
    this.dialog.open(InfoDialog, {
      width: '500px',
      data: { title: `User Info`, content: `We request our users to add credit through the Mvaahna mobile app. Once your transaction is complete refresh the application, it will be reflected soon in the web application. Thank you for your understanding. ?` }
    }).afterClosed().subscribe((res: any) => {
      if (res) {
      }
    });
  }


}


@Component({
  selector: 'info-dialog',
  template: `
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="warningDialogLabel" style="color: #235B9D;">{{displayData.title}}</h2>
      </div>
      <div class="modal-body mt-2 mb-2 fs-6">
        <p>{{displayData.content}}</p>
      </div>
      <div class="modal-footer gap-2">
        <button type="button" class="btn btn-primary" (click)="closeDialog(false)">Okay</button>
      </div>
    </div>
  </div>
  `,
})
export class InfoDialog {
  constructor(public dialogRef: MatDialogRef<InfoDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  displayData: any = this.data;
  closeDialog(flag: boolean) {
    this.dialogRef.close(flag);
  }
}
