import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, interval, takeWhile } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Output() onCloseModal: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(SlickCarouselComponent) slickCarousel!: SlickCarouselComponent;

  mobileLogin: FormGroup = this.formBuilder.group({
    mobile_number: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')])
  });

  mobileRegister: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required]],
    mobile_number: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')])
  });

  emailLogin: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required]],
    password: ['', [Validators.required]]
  });

  emailRegister: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    name: ['', [Validators.required]],
    phone: ['', [Validators.required, Validators.minLength(6)]],
    password: ['', [Validators.required, Validators.minLength(6), Validators.pattern(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]{8,}$/)]],
    confirmPassword: ['', Validators.required]
  }, { validators: this.passwordMatchValidator });

  gmailRegister: FormGroup = this.formBuilder.group({
    country: ['', [Validators.required]],
    country_id: [''],
    email: ['', [Validators.email]],
    name: ['', [Validators.required]],
    phone: ['', [Validators.required, Validators.minLength(6)]],
    email_sub: ['',]
  })

  // forgotPassword: FormGroup = this.formBuilder.group({
  //   email: ['', [Validators.required, Validators.email]],
  // });

  forgotPassword: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: [''],
    confirmPassword: ['']
  }, { validators: this.passwordMatchValidator });
  receivedData: any;
  login_step: number = 1;
  // mobile_number!: string;
  otp!: string[];
  otpInvalid: boolean = false;
  selectedCountry: any
  selectedCountryObj: any
  countries: any;
  disabled: boolean = true;
  displayTime!: number;
  timer$!: Observable<number>;
  startTime = 60;
  newReg: boolean = false;
  forgotPasswordFlag: boolean = false
  showResetPassword: boolean = false

  constructor(private Router: Router, private el: ElementRef, private renderer: Renderer2, private dialogRef: MatDialogRef<LoginComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private Toaster: ToastrService, private userService: UserService, private formBuilder: FormBuilder, private loader: NgxSpinnerService, private authService: AuthService, private dialog: MatDialog) {
    if (this.data) {
      this.login_step = (this.data.step != 0) ? this.data.step : 1
      this.changeStep(this.login_step, '')
    }
  }

  ngOnInit(): void {
    this.getAllCountries()
  }

  ngAfterViewInit(): void {
    this.dialogRef.afterOpened().subscribe(() => {
      if (this.slickCarousel) {
        setTimeout(() => {
          this.slickCarousel.slickPlay();
        }, 100);
      }
    });

    this.renderer.listen('window', 'resize', (event) => {
      const dialogWidth = this.dialogRef._containerInstance._config.width;
      const dialogHeight = this.dialogRef._containerInstance._config.height;
      const dialogWidthInPx = window.innerWidth * (parseInt(dialogWidth ?? '', 10) / 100);
      const dialogHeightInPx = window.innerHeight * (parseInt(dialogHeight ?? '', 10) / 100);
      const dialog = dialogHeightInPx
      const toolbar = this.el.nativeElement.querySelector('#top_bar').offsetHeight;
      const main_height = dialog - toolbar - 16;
      if (this.el.nativeElement.querySelector('#work_area') != null) {
        this.el.nativeElement.querySelector('#work_area').style.height = main_height + 'px'
      }
    })
  }

  passwordMatchValidator(form: FormGroup) {
    const password = form.get('password')?.value;
    const confirmPassword = form.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { mismatch: true };
  }
  gmailData: any;
  gmailLogin(data: any) {
    this.loader.show();
    this.gmailData = JSON.parse(Buffer.from(data.credential.split('.')[1], 'base64').toString('utf-8'));
    console.log(this.gmailData);
    this.gmailRegister.controls['email'].setValue(this.gmailData.email)
    this.gmailRegister.controls['email_sub'].setValue(Buffer.from(this.gmailData.sub, 'utf-8').toString('base64'))
    this.gmailRegister.controls['name'].setValue(this.gmailData.name)
    this.userService.checkEmailSub(this.gmailRegister.value).subscribe((res: any) => {
      if (res.status == 1) {
        this.loader.hide();

        sessionStorage.setItem("user_id", res.data[0].id);
        sessionStorage.setItem('userData', JSON.stringify(res.data[0]))

        this.gmailRegister = this.formBuilder.group({
          country: ['', [Validators.required]],
          country_id: [''],
          email: ['', [Validators.email]],
          name: ['', [Validators.required]],
          phone: ['', [Validators.required, Validators.minLength(6)]],
          email_sub: ['',]
        })
        this.dialogRef.close();
        // this.Router.navigate(['/user-landing']);
        this.Toaster.success('logged in successfully..!', '', {
          timeOut: 3000
        })

      } else if (res.status == 2) {
        this.loader.hide();
        this.Toaster.warning('Email id already registered with us..!', '', {
          timeOut: 3000
        })
      } else if (res.status == 3) {
        this.loader.hide();
        this.changeStep(6, '');
      } else {
        this.loader.hide();
        this.Toaster.error('Server Busy..!', '', {
          timeOut: 3000
        })
      }
    })
  }



  slidesStore: any = [
    { src: 'assets/carousel-1.svg', title: 'Find Trusted Mechanics at your Fingertips', desc: 'Locate and Choose services from user-rated mechanic shops' },
    { src: 'assets/carousel-2.svg', title: 'Monitor Vehicle Health and Efficiency', desc: 'Keep track of vehicle mileage, fuel efficiency and related costs' },
    { src: 'assets/carousel-3.svg', title: 'Emergency Assistance Service', desc: 'Get quick support from garages near you for emergency scenarios' }
  ];
  slideConfig = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    pauseOnHover: false,
    infinite: true,
    responsive: []
  };

  getAllCountries() {
    this.userService.getAllCountries().subscribe((res: any) => {
      if (res.status == 1) {
        this.countries = res.data
      }
    })
  }

  showNumberField: boolean = false
  showEmailField: boolean = false
  showPassword: boolean = false
  onSelect(x: any) {
    this.selectedCountryObj = x
    this.selectedCountry = x.name;
    // console.log(this.selectedCountry);
    this.gmailRegister.controls['country'].setValue(x.name)
    this.gmailRegister.controls['country_id'].setValue(x.id)

    if (x.name == 'India') {
      this.otp = ['', '', '', '']
      this.showNumberField = true
      this.showEmailField = false
    } else {
      this.otp = ['', '', '', '', '', '']
      this.showNumberField = false
      this.showEmailField = true
      // this.selectedCountry = ''
    }
  }

  resend() {

    if (!this.disabled) {
      if (this.selectedCountry == 'India') {
        let input = (!this.newReg) ? { ...this.mobileLogin.value, ...{ mobile_number: this.selectedCountryObj.code.toString() + this.mobileLogin.value.mobile_number.toString() } } : { ...this.mobileRegister.value, ...{ mobile_number: this.selectedCountryObj.code.toString() + this.mobileRegister.value.mobile_number.toString() } }
        this.userService.getOTP(input).subscribe((res: any) => {
          if (res.status == 1) {
            this.timer$ = interval(1000);
            this.disabled = true
            this.displayTime = 60
            this.startTime = 60
            this.startTimer()
            this.Toaster.success('An OTP has been sent to the entered mobile number', '', {
              timeOut: 3000
            })
            this.changeStep(4, '');
          } else {
            this.Toaster.error('Something went wrong.please try again..!', '', {
              timeOut: 3000
            })
          }
        })
      } else {
        let input = { ...this.emailRegister.value, ...{ phone: this.selectedCountryObj.code.toString() + this.emailRegister.value.phone.toString() }, ...{ password: Buffer.from(this.emailRegister.value.password, 'utf-8').toString('base64') }, ...{ country: this.selectedCountry } }
        this.userService.getCustOTP(input).subscribe((res: any) => {
          if (res.status == 1) {
            this.timer$ = interval(1000);
            this.disabled = true
            this.displayTime = 60
            this.startTime = 60
            this.startTimer()
            this.Toaster.success('An OTP has been sent to the entered mobile number', '', {
              timeOut: 3000
            })
            this.changeStep(4, '');
          } else if (res.status == 3) {
            this.Toaster.warning('The entered email is already registered', '', {
              timeOut: 3000
            })
          }
          else {
            this.Toaster.error('Something went wrong.please try again..!', '', {
              timeOut: 3000
            })
          }
        })
      }
    }
  }

  startTimer() {
    this.timer$ = interval(1000);
    this.timer$
      .pipe(takeWhile(() => this.displayTime !== 0))
      .subscribe(() => {
        this.displayTime = this.startTime--;
        if (this.displayTime === 0) {
          this.disabled = false

        }
      });
  }

  changeStep(step: number, nav: string): void {
    this.login_step = (nav == '') ? step : step - 1
    if (this.login_step != 1) {
      setTimeout(() => {
        console.log("hii");

        // const dialog = this.el.nativeElement.querySelector('#dialog-box').offsetHeight;
        const dialogWidth = this.dialogRef._containerInstance._config.width;
        const dialogHeight = this.dialogRef._containerInstance._config.height;
        const dialogWidthInPx = window.innerWidth * (parseInt(dialogWidth ?? '', 10) / 100);
        const dialogHeightInPx = window.innerHeight * (parseInt(dialogHeight ?? '', 10) / 100);
        const dialog = dialogHeightInPx
        const toolbar = this.el.nativeElement.querySelector('#top_bar').offsetHeight;
        const main_height = dialog - toolbar - 16;
        if (this.el.nativeElement.querySelector('#work_area') != null) {
          this.el.nativeElement.querySelector('#work_area').style.height = main_height + 'px'
        }
      }, 1000);
    } else {
      this.showEmailField = false
      this.showNumberField = false
      this.selectedCountry = ''
    }
    // if (step == 1) this.initializeGoogleSignIn()
  }

  onKeyUp(event: any, index: number) {
    const inputValue = event.target.value;
    const otp_box = (this.selectedCountry == 'India') ? 3 : 5
    if (inputValue.length === 1 && index < otp_box) {
      const nextElement = event.target.parentElement?.nextElementSibling?.querySelector('input');
      if (nextElement) {
        nextElement.focus();
      }
    } else if (inputValue.length === 0 && index > 0) {
      const prevElement = event.target.parentElement?.previousElementSibling?.querySelector('input');
      if (prevElement) {
        prevElement.value = '';
        prevElement.focus();
      }
    }
    this.validateOTP();
  }


  validateOTP() {
    this.otpInvalid = this.otp.some(digit => digit === '');
  }


  getOTP() {
    let input

    if (this.selectedCountry == 'India') {
      input = { ...this.mobileLogin.value, ...{ mobile_number: this.selectedCountryObj.code.toString() + this.mobileLogin.value.mobile_number.toString() } }
      this.userService.getOTP(input).subscribe((res: any) => {
        if (res.status == 1) {
          this.timer$ = interval(1000);
          this.disabled = true
          this.displayTime = 60
          this.startTime = 60
          this.startTimer()
          this.Toaster.success('An OTP has been sent to the entered mobile number', '', {
            timeOut: 3000
          })
          this.changeStep(4, '');
        } else if (res.status == 2) {
          this.Toaster.success(res.msg, '', {
            timeOut: 3000
          })

          this.dialog.open(ActivateUserDialog, {
            width: '500px',
            data: { title: `User Info`, content: `This account is in In-Active status.Do you want to activate it again ?` }
          }).afterClosed().subscribe((res: any) => {
            if (res) {
              this.loader.show();
              this.authService.changeStatus({ mobile_number: this.selectedCountryObj.code.toString() + this.mobileLogin.value.mobile_number.toString(), status: 'A' }).subscribe((res: any) => {
                if (res.status == 1) {
                  this.loader.hide();
                  this.Toaster.success('', 'Account Reactivated...!', {
                    timeOut: 3000
                  })
                }
              })
            }
          });
        } else {
          this.Toaster.error('Something went wrong.please try again..!', '', {
            timeOut: 3000
          })
        }
      })
    } else {
      input = { ...this.emailLogin.value, ...{ password: Buffer.from(this.emailLogin.value.password, 'utf-8').toString('base64') } }
      this.userService.emailLogin(input).subscribe((res: any) => {
        if (res.status == 1) {
          this.Toaster.success('Logged In Successfully..!', '', {
            timeOut: 3000
          })
          this.Router.navigate(['/mvaahna-mechanic']);
          this.dialogRef.close()
        } else if (res.status == 0) {
          this.Toaster.warning('Invalid Login..!', '', {
            timeOut: 3000
          })
        } else {
          this.Toaster.error('Something went wrong.please try again..!', '', {
            timeOut: 3000
          })
        }
      })
    }

  }

  register() {
    // console.log(this.selectedCountry);

    let input
    if (this.selectedCountry == 'India') {
      input = { ...{ ...this.mobileRegister.value, ...{ mobile_number: this.selectedCountryObj.code.toString() + this.mobileRegister.value.mobile_number.toString() } }, ...{ country: this.selectedCountry } }
      this.userService.getOTP(input).subscribe((res: any) => {
        if (res.status == 1) {
          this.timer$ = interval(1000);
          this.disabled = true
          this.displayTime = 60
          this.startTime = 60
          this.startTimer();
          this.Toaster.success('An OTP has been sent to the entered mobile number', '', {
            timeOut: 3000
          })
          this.newReg = true;
          this.changeStep(4, '');
        } else if (res.status == 2) {
          this.Toaster.success(res.msg, '', {
            timeOut: 3000
          })
        }
        else {
          this.Toaster.error('Something went wrong.please try again..!', '', {
            timeOut: 3000
          })
        }
      })
    } else {
      input = { ...this.emailRegister.value, ...{ phone: this.selectedCountryObj.code.toString() + this.emailRegister.value.phone.toString() }, ...{ password: Buffer.from(this.emailRegister.value.password, 'utf-8').toString('base64') }, ...{ country: this.selectedCountry } }
      this.userService.getCustOTP(input).subscribe((res: any) => {
        if (res.status == 1) {
          this.timer$ = interval(1000);
          this.disabled = true
          this.displayTime = 60
          this.startTime = 60
          this.startTimer()
          this.Toaster.success('An OTP has been sent to the entered mobile number', '', {
            timeOut: 3000
          })
          this.changeStep(4, '');
        } else if (res.status == 3) {
          this.Toaster.warning('The entered email is already registered', '', {
            timeOut: 3000
          })
        } else {
          this.Toaster.error('Something went wrong.please try again..!', '', {
            timeOut: 3000
          })
        }
      })
    }
  }

  verifyOTP() {
    // console.log(this.otp);
    const otp = parseInt(this.otp.join('')).toString();

    let input = (this.selectedCountry == 'India') ? (this.newReg) ? { ...{ ...this.mobileRegister.value, ...{ mobile_number: this.selectedCountryObj.code.toString() + this.mobileRegister.value.mobile_number.toString() } }, ...{ phone: this.selectedCountryObj.code.toString() + this.mobileRegister.value.mobile_number.toString() }, ...{ otp: otp } } : { ...{ ...this.mobileLogin.value, ...{ mobile_number: this.selectedCountryObj.code.toString() + this.mobileLogin.value.mobile_number.toString() } }, ...{ otp: otp } } : { ...this.emailRegister.value, ...{ phone: this.selectedCountryObj.code.toString() + this.emailRegister.value.phone.toString() }, ...{ password: Buffer.from(this.emailRegister.value.password, 'utf-8').toString('base64') }, ...{ otp: otp }, ...{ country: this.selectedCountry } }


    if (this.selectedCountry == 'India') {

      this.userService.verifyOTP(input).subscribe((res: any) => {
        if (res.status == 1) {
          // console.log(res);
          // this.Router.navigate(['/user']);
          if (this.newReg) {

            this.userService.createCustomer(input).subscribe((res1: any) => {
              if (res1.status == 1) {
                this.newReg = false;
                this.dialogRef.close()
                // this.Router.navigate(['/user-landing']);
                this.Toaster.success('Logged In Successfully', '', {
                  timeOut: 3000
                })
              } else {
                this.Toaster.success('The number already added', '', {
                  timeOut: 3000
                })
              }
            })
          } else {
            // this.Router.navigate(['/user-landing']);
            if (res.status == 1) {
              this.Toaster.success('Logged In Successfully..!', '', {
                timeOut: 3000
              })
              if (res.data.id) {
                this.Router.navigate(['/mvaahna-mechanic']);
              } else {
                this.Router.navigate(['/mechanic-registration', this.selectedCountryObj.code.toString() + this.mobileLogin.value.mobile_number.toString()]);
              }
              this.dialogRef.close()
            } else if (res.status == 0) {
              this.Toaster.warning('Invalid Login..!', '', {
                timeOut: 3000
              })
            } else {
              this.Toaster.error('Something went wrong.please try again..!', '', {
                timeOut: 3000
              })
            }
          }
        } else {
          this.Toaster.error('Server Busy..!', '', {
            timeOut: 3000
          })
        }
      })

    } else {

      this.userService.checkVerificationCode(input).subscribe((res: any) => {
        if (res.status == 1) {

          this.emailRegister = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            name: ['', [Validators.required]],
            phone: ['', [Validators.required, Validators.minLength(6)]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required]
          }, { validators: this.passwordMatchValidator });
          // console.log(res);
          this.changeStep(2, '')
          this.Toaster.success('User created successfully..!,kindly login', '', {
            timeOut: 3000
          })

        } else {
          this.Toaster.error('Server Busy..!', '', {
            timeOut: 3000
          })
        }
      })
    }
  }

  registerViaGmail() {
    var input = { ...this.gmailRegister.value, ...{ phone: this.selectedCountryObj.code.toString() + this.gmailRegister.value.phone.toString() } }
    this.userService.vaahnaGmailRegistration(input).subscribe((res: any) => {
      if (res.status == 1) {

        sessionStorage.setItem("user_id", res.data.id);
        sessionStorage.setItem('userData', JSON.stringify(res.data[0]))

        this.gmailRegister = this.formBuilder.group({
          country: ['', [Validators.required]],
          country_id: [''],
          email: ['', [Validators.email]],
          name: ['', [Validators.required]],
          phone: ['', [Validators.required, Validators.minLength(6)]],
          email_sub: ['',]
        })
        // this.Router.navigate(['/user-landing']);
        this.dialogRef.close();
        this.Toaster.success('logged in successfully..!', '', {
          timeOut: 3000
        })

      } else if (res.status == 2) {
        this.Toaster.warning('Mobile Number already exists..!', '', {
          timeOut: 3000
        })
      } else {
        this.Toaster.error('Server Busy..!', '', {
          timeOut: 3000
        })
      }
    })

  }

  forgotPasswordOTP(flag: string) {
    let Input
    if (flag == 'otp') {
      Input = { ...this.forgotPassword.value, ...{ country: this.selectedCountry } }
      this.userService.forgotPasswordOTP(Input).subscribe((res: any) => {
        if (res.status == 1) {
          this.forgotPasswordFlag = true;
          this.timer$ = interval(1000);
          this.disabled = true
          this.displayTime = 60
          this.startTime = 60
          this.startTimer()
          this.changeStep(4, '')
          this.Toaster.success('Verification code has been sent to given email ID', '', {
            timeOut: 3000
          })
        } else if (res.status == 3) {
          this.Toaster.warning('Enterd email ID is not registered with us..!', '', {
            timeOut: 3000
          })
        } else {
          this.Toaster.error('Server Busy..!', '', {
            timeOut: 3000
          })
        }
      })
    } else if (flag == 'verify') {
      const otp = parseInt(this.otp.join('')).toString();
      Input = {
        ...this.forgotPassword.value, ...{ otp: otp }, ...{ country: this.selectedCountry }
      }

      this.userService.forgotPasswordOTP(Input).subscribe((res: any) => {
        if (res.status == 1) {
          this.forgotPasswordFlag = false;
          this.showResetPassword = true
          this.addResetvalidation()
          this.changeStep(5, '')
          this.Toaster.success('Verification code has been sent verified sucessfully..!', '', {
            timeOut: 3000
          })
        } else {
          this.Toaster.error('Server Busy..!', '', {
            timeOut: 3000
          })
        }
      })
    } else {
      Input = { ...this.forgotPassword.value, ...{ password: Buffer.from(this.forgotPassword.value.password, 'utf-8').toString('base64') }, ...{ country: this.selectedCountry } }
      // console.log(Input);
      this.userService.changePassword(Input).subscribe((res: any) => {
        if (res.status == 1) {
          this.showResetPassword = false
          this.changeStep(2, '')
          this.Toaster.success('Password changed successfully..!Please Login', '', {
            timeOut: 3000
          })
        } else {
          this.Toaster.error('Server Busy..!', '', {
            timeOut: 3000
          })
        }
      })

    }
  }

  addResetvalidation(): void {

    const passwordControl = this.forgotPassword.get('password');
    passwordControl?.setValidators([Validators.required, Validators.minLength(6), Validators.pattern(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]{8,}$/)]);

    const confirmPasswordControl = this.forgotPassword.get('confirmPassword')
    confirmPasswordControl?.setValidators([Validators.required]);

    passwordControl?.updateValueAndValidity();
    confirmPasswordControl?.updateValueAndValidity();
  }


  close() {
    this.dialogRef.close('guest')
  }

}


@Component({
  selector: 'activate-user-dialog',
  template: `
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="warningDialogLabel" style="color: #235B9D;">{{displayData.title}}</h2>
      </div>
      <div class="modal-body mt-2 mb-2 fs-6">
        <p>{{displayData.content}}</p>
      </div>
      <div class="modal-footer gap-2">
        <button type="button" class="btn btn-secondary" (click)="closeDialog(false)">No</button>
        <button type="button" class="btn btn-primary" (click)="closeDialog(true)">Yes</button>
      </div>
    </div>
  </div>
  `,
})
export class ActivateUserDialog {
  constructor(public dialogRef: MatDialogRef<ActivateUserDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  displayData: any = this.data;
  closeDialog(flag: boolean) {
    this.dialogRef.close(flag);
  }
}
